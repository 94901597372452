<template>
  <div>
    <v-chip
      :color="status == 1?'warning':status == 2?'accent':status == 3?'info':status == 4?'success':'error'"
      :class="status==1?`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg warning--text`:status==2?`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg accent--text`:status==3?`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg info--text`:status==4?`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg success--text`:`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg error--text`"
      small
    >
      {{ status == 1 ? $t('saveTheDraft') : status == 2 ? $t('wait_pay') : status == 3 ? $t('waitWarehouse') : status == 4 ? $t('success') : $t('cancel')
      }}
    </v-chip>
  </div>
</template>

<script>
export default {
  props: ['status'],
}
</script>
